<script setup lang="ts">
import LargeFeedback from "~/components/Feedback/LargeFeedback.vue";
import { useFeedback } from "~/composables/feedback/useFeedback";
import SmallFeedback from "~/components/Feedback/SmallFeedback.vue";

const feedbacks = useFeedback();
</script>

<template>
  <div class="feedback_container">
    <TransitionGroup name="fade" tag="ul">
      <li
        @click="feedbacks.remove(feed.uuid as string)"
        v-for="feed in feedbacks.feedbacks.value"
        :key="`key-${feed.uuid}`"
      >
        <SmallFeedback v-if="feed.size === 'small'" :feedback="feed" />
        <LargeFeedback v-if="feed.size === 'large'" :feedback="feed" />
      </li>
    </TransitionGroup>
  </div>
</template>

<style scoped>
.feedback_container {
  @apply fixed flex justify-center items-center top-4 w-full;
  z-index: 501;
}
</style>
